// src/components/CookieConsent.js
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent = () => {
  const handleAccept = () => {
    // Check if the cookie was successfully set
    const cookieExists = document.cookie.includes('myCookieConsent');

    if (!cookieExists) {
      // Handle the scenario where the cookie couldn't be set (e.g., third-party cookies blocked)
      alert("Please enable third-party cookies to use this website.");
    }
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="I accept"
      cookieName="myCookieConsent"
      style={{ background: "#333" }}s
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
      onAccept={handleAccept}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookieConsentComponent;
